import * as React from "react";
import Layout from "../components/Layout";
import Services from "../components/Services";
import Seo from "../components/Seo";
import Reservation from "../components/Reservation";

// markup
const ReservationPage = () => {
  return (
    <Layout theme="light" heroSmall>
      <Seo
        title="Ajanvaraus - Kauneuden ilo"
        description="Voit varata aikasi nettiajanvarauksen kautta 24/7, tai ottamalla yhteyttä puhelimitse numeroon 040 846 4160. Voit myös tulla tekemään varauksen paikanpäälle. Jos emme pääse puhelimeen, soitamme sinulle takaisin heti kun vapaudumme."
      />
      <Reservation />
      <Services />
    </Layout>
  );
};

export default ReservationPage;
