import * as React from "react";
import IframeResizer from "iframe-resizer-react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Reservation = () => {
  const { contentfulAjanvaraus: booking } = useStaticQuery(graphql`
    {
      contentfulAjanvaraus {
        bookingHeader
        bookingText {
          raw
        }
      }
    }
  `);
  return (
    <React.Fragment>
      <section className="booking">
        <h2>{booking?.bookingHeader}</h2>
        {renderRichText(booking?.bookingText)}
      </section>
      <section>
        <IframeResizer
          width="100%"
          frameBorder="0"
          src="https://varaa.timma.fi/reservation/Kauneudenilo"
          id="reservationIframe95318"
          style={{ width: "1px", minWidth: "100%" }}
        />
      </section>
    </React.Fragment>
  );
};

export default Reservation;
